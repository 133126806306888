body {
  margin: 0;
}

@font-face {
  font-family: 'Kallisto';
  src: url('./fonts/Kallisto-Bold.woff2') format('woff2'),
      url('./fonts/Kallisto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kallisto';
  src: url('./fonts/Kallisto-Bold.woff2') format('woff2'),
      url('./fonts/Kallisto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Druk';
  src: url('./fonts/Druk-Medium.woff2') format('woff2'),
      url('./fonts/Druk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
